<template>
  <div id="main-wrapper">

    <div class="main-content is-footer">


      <!--پرفروشترین ها--->
      <v-container >
        <v-row>
          <v-col >
            <p class="text-center font-medium font-weight-bold pt-3"><v-icon color="black" size="27">mdi-seal</v-icon> قسمت حسابداری </p>
          </v-col>
        </v-row>


        <v-card color="#8f08ea" class="flex" v-if="pageloading">
          <v-btn @click="$router.push({path:'/admin'});"  :loading="loading" depressed :color="randomColor()" height="50" style="width: 96%;margin:5px;"> <b>برگشت به صفحه قبل</b></v-btn>
        </v-card>
        <v-divider style="margin-bottom:15px;"></v-divider>
        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <v-card v-else :key="key" v-for="(item,key) in form"
                class="mx-auto"
                style="background-color: rgb(215, 211, 243);margin-bottom: 10px"

        >
          {{ key+1 }})<span style="font-size: 13px"><b>سریال </b> : {{ item.sh_barge }} </span>
          <span style="font-size: 13px"><b>مبلغ </b> : {{ item.mablagh }} </span><br>
          <span style="font-size: 13px"><b>دوره مسابقات </b> : {{ item.dore }} </span><br>
          <span style="font-size: 13px"><b>توضیحات </b> : {{ item.noe }} </span><br>

          <div class="flex">
          </div>
        </v-card>
        <v-divider style="margin-top:15px;"></v-divider>
        <v-card color="#8f08ea" class="flex" v-if="pageloading">
          <v-btn @click="hesab(page);"  :loading="loading" depressed :color="randomColor()"  height="50" style="width: 96%;margin:5px;"> <b>نمایش بیشتر</b></v-btn>
        </v-card>

      </v-container>


    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';
import axios from "axios";


export default {
  name: 'modir',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,

  },
  data () {
    return {
      show: true,
      url:'https://sharjtosharj.com/soccerp/',
      form:[],
      loading:false,
      sh_delete:0,
      page:0,
      pageloading: false,
      dialog: false,
    }
  },
  mounted() {
    let self = this;
    self.form=[];
    self.hesab(0);

  },
  computed:{
    ...mapGetters(['baseUrl',"auth"]),
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{

    randomColor() {
      const r = () => Math.floor(400 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },

    hesab($page){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("noe", self.$route.params.noe);
      formData.append("Offset", self.page);
      formData.append("Limit", 10);

      axios.post(this.baseUrl+'/hesabdari.aspx', formData, config)
          .then(function (r) {
            //console.log(r.data);
            if(r.data.result=="1"){

              var myarr = r.data.data;

              myarr.forEach((value, index) => {
                self.form.push(value);
              });

              self.pageloading=true;
              if (self.form.length==0)
                self.$router.push({path:'/admin'});

             /* if (myarr.length <= 0)
                self.helperToast({
                  show:true,
                  text:"دیگر فرمی وجود ندارد",
                  color:'red'
                });*/
            }
            else
            {self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'red'
            });
              if (self.form.length==0)
              self.$router.push({path:'/admin'});

            }
            self.loading = false;
          });


        self.page=self.page+10;

    },
    handleScroll (event) {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.hesab(this.page);
      }
    },

  },
  watch:{}
}
</script>

<style>
.border-grid .col{
  height: 100px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid .col:nth-child(3n + 0){
  border-left: none;
}
.border-grid .col:nth-last-child(-n + 3){
  border-bottom: none;
}
.border-grid .col p{
  font-weight: 600;
}

.border-grid2 .col{
  height: 300px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid2 .col:nth-child(2n + 0){
  border-left: none;
}
.border-grid2 .col:nth-last-child(-n + 2){
  border-bottom: none;
}
.border-grid2 .col p{
  font-weight: 600;
}
.flex {
  display: flex;
}


</style>
